/* src/styles/SocialSignInButton.css */
.social-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-background-color, #f5f5f5);
    color: var(--button-text-color, #000);
    border: none;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .social-sign-in-button .icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .social-sign-in-button:hover {
    background-color: var(--button-hover-background-color, #e0e0e0);
  }
  
  /* src/styles/SocialSignInButton.css */
.social-sign-in-button {
    /* Use your existing button styles */
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    /* Rest of the styles remain the same */
  }
  