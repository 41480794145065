/* src/styles/ListView.css */

.listview {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.task-details-wrapper {
  display: flex;
  height: 100%;
}

.task-grid {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%; /* Adjusted to prevent expanding too far */
}

.grid-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.add-column-button {
  margin-left: auto;
}

.treegrid-container {
  max-height: calc(100vh - 500px); /* Adjust height to leave space for the button */
}

.task-grid-toolbar {
  flex-shrink: 0;
  padding: 10px;
}

.add-task-button {
  background-color: #2d2d2d;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  transition: box-shadow 0.3s;
}

.add-task-button:hover {
  box-shadow: 0 0 5px rgba(129, 127, 127, 0.5);
}


.add-task-button:focus {
  outline: none;
}

.task-details {
  width: 0;
  transition: width 0.3s;
}

.task-details.show {
  width: 300px;
}

.control-pane {
  flex: 1;
}

body {
  overflow: hidden; /* Prevent body scrolling */
}

.main-content {
  overflow-y: auto; /* Allow main content to scroll */
}

/* groups */

/* src/styles/ListView.css */

/* Styles for the group section */

/* Styles for the group header */
.group-header {
  display: flex;
  align-items: center;
}

/* Styles for the arrow indicators */
.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 10px;
  vertical-align: middle;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.arrow.down {
  border-left: 5px solid #000;
}

.arrow.right {
  border-top: 5px solid #000;
  border-left: none;
}


/* Styles for the add group button */
.add-group-button {
  padding: 10px 20px;
  background-color: #454545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-group-button:hover {
  background-color: #565656;
}


/* Styles for the modal when adding a group */
.add-group-modal {
  display: flex;
  flex-direction: column;
}

.add-group-modal input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-group-modal button {
  padding: 10px;
  background-color: #5aac44;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-group-modal button:hover {
  background-color: #494949;
}

/* Arrow transitions for smooth UI */
.group-header .arrow {
  transition: transform 0.2s ease;
}

.group-header .arrow.down {
  transform: rotate(90deg);
}

.group-header .arrow.right {
  transform: rotate(0deg);
}
