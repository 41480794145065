/* src/styles/SignInPage.css */
.marketing-page {
    text-align: center;
    padding: 50px 20px;
  }
  
  .hero-section {
    padding: 80px 20px;
  }
  
  .hero-section h1 {
    font-size: 48px;
    margin-bottom: 20px;
    color: var(--primary-text-color);
  }
  
  .hero-section p {
    font-size: 18px;
    margin-bottom: 30px;
    color: var(--secondary-text-color);
  }
  
  .primary-button {
    background-color: var(--primary-button-color);
    color: var(--button-text-color);
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .primary-button:hover {
    background-color: var(--primary-button-hover-color);
  }
  
  .features-section {
    display: flex;
    justify-content: space-around;
    padding: 60px 20px;
  }
  
  .feature {
    max-width: 300px;
  }
  
  .feature h2 {
    font-size: 28px;
    margin-bottom: 15px;
    color: var(--primary-text-color);
  }
  
  .feature p {
    font-size: 16px;
    color: var(--secondary-text-color);
  }
  
  .call-to-action-section {
    padding: 60px 20px;
  }
  
  .call-to-action-section h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: var(--primary-text-color);
  }
  