/* src/styles/SignInForm.css */
.sign-in-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 30px;
  }
  
  .form-group input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--input-border-color, #ccc);
    outline: none;
    padding: 8px 0;
    font-size: 16px;
    background: transparent;
  }
  
  .form-group label {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--label-color, #999);
    font-size: 16px;
    transition: all 0.2s ease;
    pointer-events: none;
  }
  
  .form-group input:focus + label,
  .form-group input:not(:placeholder-shown) + label {
    top: -20px;
    font-size: 14px;
    color: var(--label-active-color, #333);
  }
  
  .form-group .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  
  .form-group .bar::before {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: var(--accent-color, #6200ee);
    transition: width 0.2s ease;
  }
  
  .form-group input:focus ~ .bar::before {
    width: 100%;
  }
  
  .submit-button {
    background-color: var(--accent-color, #6200ee);
    color: #fff;
    border: none;
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: var(--accent-color-dark, #3700b3);
  }
  
  /* src/styles/SignInForm.css */
/* Adjusted to use your existing styles and variables */
.form-group input {
    border-bottom: 1px solid var(--input-border-color);
    color: var(--primary-text-color);
  }
  
  /* Rest of the styles remain the same */
  