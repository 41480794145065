/* src/styles/SignInModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .sign-in-modal {
    position: relative;
    width: 400px;
    padding: 40px;
    border-radius: 8px; /* Rounded corners */
    background: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Frosted glass effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  }
  
  .sign-in-modal .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-text-color, #fff);
  }
  
  .sign-in-modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: var(--primary-text-color, #fff);
  }
  
  .social-sign-in-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .divider {
    text-align: center;
    margin: 20px 0;
    position: relative;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: var(--divider-color, #ccc);
  }
  
  .divider::before {
    left: 0;
  }
  
  .divider::after {
    right: 0;
  }
  
  .divider span {
    background-color: transparent;
    padding: 0 10px;
    color: var(--text-muted-color, #fff);
  }
  
  .forgot-password {
    text-align: right;
    margin-top: 10px;
  }
  
  .forgot-password a {
    color: var(--accent-color, #fff);
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  