@import url('https://cdn.syncfusion.com/ej2/material.css');

/* App.css */
body {
    font-family: 'Proxima Nova', Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #1e1f21;
    color: #DCDCDC;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    width: 98%;
    background-color: #2e2e30;
    color: #DCDCDC;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    border-bottom: 1px solid #333335;
    height: 30px;
  }
  
  .main {
    display: flex;
    flex-grow: 1;
    margin-top: 0;
  }
  
  .sidebar {
    width: 240px;
    background-color: #2e2e30;
    color: #DCDCDC;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    height: calc(100vh - 50px); /* Adjusted for header height */
  }
  
  .sidebar.hide {
    transform: translateX(-100%);
  }
  
  .sidebar .sidebar-link {
    color: #DCDCDC;
    text-decoration: none;
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .sidebar .sidebar-link:hover {
    text-decoration: underline;
  }
  
  .sidebar .sidebar-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 20px 0 10px 0;
    font-weight: bold;
  }
  
  .sidebar .sidebar-section-list {
    margin: 10px 0;
  }
  
  .main-content {
    width: 100%; /* Make sure main-content has a width */
    padding: 20px;
    overflow-y: auto;
    background-color: #1e1f21;
    position: relative; /* This is the key change */
  }
  
  .main-content.with-sidebar {
    margin-left: 10px;
  }
  
  .main-content.collapsed {
    margin-left: 10;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 5px;
    background-color: #2e2e30;
  }
  
  .search-bar input {
    margin-left: 8px;
    padding: 5px;
    background-color: #2e2e30;
    border: 1px solid #DCDCDC;
    color: #DCDCDC;
    border-radius: 20px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-info button {
    margin-left: 10px;
    background-color: #2e2e30;
    border: 1px solid #DCDCDC;
    color: #DCDCDC;
    border-radius: 20px;
  }
  
  hr {
    border: none;
    border-top: 1px solid #333335;
    margin: 10px 0;
  }
  
.project-page {
  padding: 0px;
}

.project-header {
  margin-bottom: 20px;
}

.main-wrapper {
  display: flex;
  height: 100%;
}

.default-sidebar {
  position: relative;
  z-index: 999;
  width: 250px;
}

.main-content {
  flex-grow: 1;
  padding: 0px;
  overflow-y: auto;
}

.custom-panel {
  border-radius: 20px !important;
  background: transparent !important;
}


/* Set up horizontal scrolling specifically for the TreeGrid to ensure that the frozen columns remain static */
.treegrid-container {
  flex-grow: 1;
  width: 100%;
  max-width: 1400px; /* Ensure the TreeGrid only takes up the available space within its view */
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 0px;
}


